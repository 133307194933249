<template>
  <div class="w-100 d-flex flex-column h-100">
    <base-tool-bar title="Objectifs" subtitle="Objectifs du collaborateurs"></base-tool-bar>
    <base-form class="mr-md-3" @click="updateCollaborateur" :loading="loading">
      <base-form-group title="QSSER" dense icon="user">
        <base-form-row row>
          <base-input
            v-model="objectif_mensuel_animations"
            inputText="Objectif mensuel d'animation(s) SSER"
            :errors="feedback.objectif_mensuel_animations"
          ></base-input>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import BaseToolBar from "@/components/bases/ToolBar.vue";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { errHandler } from "@/helpers.js";
import { mapActions } from "vuex";
import BaseInput from "@/components/bases/Input.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";

export default {
  components: {
    BaseToolBar,
    BaseInput,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
  },
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  methods: {
    ...mapActions({
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
    }),
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    ...mapFields("collaborateurs", ["collaborateur.id", "collaborateur.objectif_mensuel_animations"]),
  },
};
</script>
<style lang="css"></style>
